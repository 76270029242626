<template>
  <div class="">
    <v-card class="m-0 p-2">
      <p class="m-0 p-0 mb-2 ms-2 fs-13pt">
        <span class="me-5">Inscrições Docentes por UCDs</span>
        <v-badge inline :content="inscricoesVet.length">
          <span class="fs-9pt">inscritos</span>
        </v-badge>
      </p>
      <v-text-field class="m-0 p-0 mb-2" v-model="search2" append-icon="mdi-magnify" label="Filtrar UCDs" solo dense hide-details></v-text-field>
      <v-data-table dense sort-by="nome" :sort-desc="false":headers="headers1" :items="ucsVet" :search="search2" :items-per-page="10">
        <template v-slot:item="{ item }">
          <tr class="fs-9pt">
            <td class="fs-9pt">
              <span v-for="(area, key, index) in item.areas" :key="key">
                <span></span>
                <tagarea class="me-1" :sigla="area.sigla"/>
              </span>
            </td>
            <td class="fs-9pt">
              <p class="m-0 p-0 fs-10pt fw-500">{{item.nome.substr(0,80)}}</p>
            </td>
            <td class="fs-9pt">
              <span class="fs-10pt fw-500" v-c:V v-if="item.oferta2022S1">
                <v-icon class="fs-12pt" v-c:V>check</v-icon>
                SIM
              </span>
              <span class="fs-10pt fw-300" v-c:D v-if="!item.oferta2022S1">
                <v-icon class="fs-10pt" v-c:D>close</v-icon>
                NÃO
              </span>
            </td>
            <td class="fs-9pt">
              <tooltipprofs :profs="item.profs20221" />
            </td>
            <td class="fs-9pt">
              <span class="fs-10pt fw-500" v-c:V v-if="item.oferta2022S2">
                <v-icon class="fs-12pt" v-c:V>check</v-icon>
                SIM
              </span>
              <span class="fs-10pt fw-300" v-c:D v-if="!item.oferta2022S2">
                <v-icon class="fs-10pt" v-c:D>close</v-icon>
                NÃO
              </span>
            </td>
            <td class="fs-9pt">
              <tooltipprofs :profs="item.profs20222" />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "",
  components: {
    //'docente': () => import('./docente.vue'),
    'avataronline': () => import('@/components/avatar-online.vue'),
    'tagarea': () => import("./inscricaoUCD/tagArea.vue"),
    'tooltipsucs': () => import("./tooltip-ucs.vue"),
    'tooltipprofs': () => import("./tooltip-profs.vue"),
  },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      areas: {},
      ucs: {},
      ucsVet: [],
      inscricoesVet: [],
      search2: "",
      headers1: [
        { text: 'Área', align: 'start', sortable: true, value: 'areasSiglas'},
        { text: 'UCD', align: 'start', sortable: true, value: 'nome'},
        { text: 'Oferta 2022/1', align: 'start', sortable: true, value: 'oferta2022S1', width: '80px'},
        { text: 'Inscritos 2022/1', align: 'start', sortable: true, value: 'inscritos', width: '80px'},
        { text: 'Oferta 2022/2', align: 'start', sortable: true, value: 'oferta2022S2', width: '80px'},
        { text: 'Inscritos 2022/2', align: 'start', sortable: true, value: 'inscritos', width: '80px'},
      ],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;
    rdb.ref('/curriculo/areas').on('value',function(snapshot) {
      self.areas = snapshotValToArray(snapshot.val());
      console.log("self.areas",self.areas);
      //self.build();
    });

    rdb.ref("/curriculo/ofertas/ucsLive/").on('value',function(snapshot) {
      self.ucs = snapshot.val();
      for(var key in self.ucs) {
        self.ucs[key].profs20221 = {};
        self.ucs[key].profs20222 = {};
        self.ucs[key].areasSiglas = "";
        for(var keyA in self.ucs[key].areas) {
          self.ucs[key].areasSiglas = self.ucs[key].areasSiglas + self.ucs[key].areas[keyA].sigla;
        }
      }
      console.log("self.ucs",self.ucs);
      self.build();
    });

    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    addInscricao(vet, inscricao) {
      rdb.ref('/usuarios/pessoas/'+inscricao.id+'/nome').once('value',function(snapshot) {
        inscricao.nome = snapshot.val();
        rdb.ref('/usuarios/pessoas/'+inscricao.id+'/email').once('value',function(snapshot) {
          inscricao.email = snapshot.val();
          vet.push(inscricao);
        });
      });
    },

    build() {
      var self = this;
      console.log("build");
      rdb.ref("/curriculo/2021S2/inscricoesUCD/").on('value',function(snapshot) {
        var inscricoes = snapshot.val();
        self.inscricoesVet = snapshotValToArray(inscricoes);
        console.log("inscricoes",inscricoes);
        if(inscricoes != null) {
          self.ucsVet = [];
          for(var userID in inscricoes) {
            if(inscricoes[userID].ucs20221Sel != undefined) {
              for(var uckey in inscricoes[userID].ucs20221Sel) {
                if(self.ucs[uckey] != undefined) {
                  self.ucs[uckey].profs20221[userID] = true;
                }
              }
            }
            if(inscricoes[userID].ucs20222Sel != undefined) {
              for(var uckey in inscricoes[userID].ucs20222Sel) {
                if(self.ucs[uckey] != undefined) {
                  self.ucs[uckey].profs20222[userID] = true;
                }
              }
            }
          }
          for(var key in self.ucs) {
            self.ucsVet.push(self.ucs[key]);
          }
          console.log("self.ucsVet",self.ucsVet);
        } else {
          self.ucsVet = [];
        }
      });


    }
  }
}
</script>

<style scoped>
</style>
